// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// allows us to use DB
import "firebase/database";


// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "API_KEY",
  authDomain: "PROJECT_ID.firebaseapp.com",
    // For databases not in the us-central1 location, databaseURL will be of the
  // form https://[databaseName].[region].firebasedatabase.app.
  // For example, https://your-database-123.europe-west1.firebasedatabase.app
  databaseURL: "https://disclose-e0644-default-rtdb.firebaseio.com/",
  projectId: "PROJECT_ID",
  storageBucket: "PROJECT_ID.appspot.com",
  messagingSenderId: "SENDER_ID",
  appId: "APP_ID",
  measurementId: "G-MEASUREMENT_ID",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
const database = firebase.database();


const getTimestamp = () => {
  const event = new Date(Date.now())
  return event.toLocaleString('en-US', { timeZone: 'UTC' })
}


// Get the Analytics service for the default app
const analytics = firebase.analytics();


// https://firebase.google.com/docs/emulator-suite/connect_and_prototype#web_1
// https://github.com/firebase/snippets-web/blob/3f94f3a87fe1e522eca95632c9725c8cdcc5810e/database/emulator-suite.js#L10-L14
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  database.useEmulator("localhost", 9000);
} 


export const saveSubmission = ({
    email,
    link,
    questions,
  } = {}) => {
  const submissions = database.ref('submissions')
  const new_submission = submissions.push()
  return new_submission.set({
    email: email,
    link: link,
    questions: questions,
    created: getTimestamp(),
  })
}


export const sendSubmissionEvent = (number_of_questions) => {
  analytics.logEvent(
    'submission',
    {
      number_of_questions,
    }
  )
}


export const sendErrorEvent = (description, fatal) => {
  analytics.logEvent(
    'exception',
    {
      description,
      fatal,
    }
  )
}


export const sendClickEvent = (button_name, value) => {
  analytics.logEvent(
    'click',
    {
      button_name,
      value,
    }
  )
}
