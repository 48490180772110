<template>
  <div id="alert-wrapper">
    <b-alert
      v-for="alert in AlertService.alerts_array"
      v-bind:key="alert.message"
      :variant="alert.type"
      show
      dismissible
      @dismissed="dismissAlert(alert.message)"
    >{{alert.message}}</b-alert>
  </div>
</template>


<script>
import AlertService from '@/services/alert_service'


export default {
  name: 'Alerts',

  data: function () {
    return {
      AlertService: AlertService,
    }
  },

  methods: {
    dismissAlert: function(alert_message) {
      console.log('dismissAlert', alert_message)
      AlertService.removeAlert(alert_message)
    }
  }
}
</script>


<style scoped>
  #alert-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
</style>
