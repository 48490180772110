export default {
  alerts_map: new Map(),
  alerts_array: [],


  // TODO: handle duplicate alerts
  addAlert: function(message, type) {
    console.log('addAlert', message, type)
    console.log('addAlert', this.alerts_array)
    this.alerts_map.set(
      message,
      {
        message: message,
        type: type,
      }
    )
    this.alerts_array.push({
      message: message,
      type: type,
    })
  },


  addErrorAlert: function(message) {
    console.log('addErrorAlert', message)
    this.addAlert(message, 'danger')
  },


  addInfoAlert: function(message) {
    this.addAlert(message, 'secondary')
  },


  addSuccessAlert: function(message) {
    this.addAlert(message, 'success')
  },


  addWarningAlert: function(message) {
    this.addAlert(message, 'warning')
  },


  getAlertsAsArray: function() {
    const array = []
    this.alerts_map.forEach(
      (value) => array.push(value)
    )
    return array
  },


  removeAlert: function(message) {
    this.alerts_map.delete(message)
    this.alerts_array = this.getAlertsAsArray()
  },
}
