<template>
  <b-container>
    <b-overlay :show="show_spinner">
      <b-form @submit="submit">

        <b-row>
          <b-col sm="6">
            <b-form-group
              id="input-group-1"
              label="Email address:"
              label-for="input-1"
              description="we'll never share your email with anyone."
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              id="input-group-2"
              label="Link to Disclosure:"
              label-for="input-2"
              description="make sure this link is shareable"
            >
              <b-form-input
                id="input-2"
                v-model="form.disclosure_link"
                type="url"
                placeholder="download link"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group id="input-group-3" label="Questions:" label-for="input-3">
              <b-form-checkbox-group
                id="input-4"
                class="text-left"
                v-model="selected_questions"
                :options="questions"
                @change="selectQuestion"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              id="input-group-2"
              label="Add your own questions:"
              label-for="input-2"
              description="simple, short, direct questions work best"
            >
              <b-button-toolbar>
                <b-input-group>
                  <b-form-input
                    id="input-2"
                    v-model="form.new_question"
                    placeholder="what do you want to know?"
                  ></b-form-input>
                </b-input-group>
                <b-button-group>
                  <b-button
                    variant="outline-secondary"
                    @click="addQuestionClick">add</b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          size="lg"
          type="submit"
          variant="outline-primary">submit</b-button>

      </b-form>
    </b-overlay>
  </b-container>
</template>


<script>
import {
  saveSubmission,
  sendSubmissionEvent,
  sendClickEvent,
  sendErrorEvent,
} from '../initialize_firebase'
import AlertService from '../services/alert_service'


const SELECT_ALL = 'Select All'


export default {
  name: 'EmailForm',


  data: function() {
    return {
      form: {
        email: '',
        disclosure_link: '',
        new_question: '',
      },
      questions: [
        SELECT_ALL,
        'How old is the furnace?',
        'How old is the roof?',
        'How old is the water heater?',
        'Is the house in a earthquake zone?',
        'Is the house in a flood zone?',
        'Is the house in one mile of a oil or gas well?',
        // 'Is the house near an earthquake fault?',
        'Is the house near an oil or gas well?',
        'Is the roof at end of life?',
        'Is the sewer lateral public or private?',
        'Is there a gas leak?',
        'Is there dry rot?',
        'Is there fungus?',
        'Is there termite?',
        // 'When was the furnace manufactured?',
        // 'When was the water heater manufactured?',
      ],
      selected_questions: [],
      show_spinner: false,
    }
  },


  methods: {
    addQuestion: function(question) {
      if (this.canAddQuestion(question) == false) return
      this.selected_questions.push(question)
      this.questions.push(this.form.new_question)

    },

    addQuestionClick: function(event) {
      event.preventDefault()
      this.addQuestion(this.form.new_question)
      // in here rather than addQuestion because
      // we want to know if user's are adding any
      // question existing in the checkbox list
      sendClickEvent(
        'add_question',
        this.form.new_question,
      )
      this.form.new_question = ''
    },

    canAddQuestion: function(question) {
      if (this.selected_questions.indexOf(question) != -1)
        return false
      else if (question == SELECT_ALL)
        return false
      else
        return true
    },

    clearForm: function() {
      this.form.email = ''
      this.form.disclosure_link = ''
      this.form.new_question = ''
    },

    handleError: function(error) {
      console.warn('[handleError] submission error', error)
      AlertService.addErrorAlert(`there was an error sending your questions, try again later or email them to us at disclose.app@gmail.com`)
      sendErrorEvent(
        'submission saving error',
        error,
      )
    },

    handleSuccess: function(response) {
      console.log('[handleSuccess] submission success', response)
      AlertService.addSuccessAlert(`submited, we'll email the answers to ${this.form.email} in 30 - 60 minutes`)
      sendSubmissionEvent(this.selected_questions.length)
      this.clearForm()
    },

    hideSpinner: function() {
      this.show_spinner = false
    },

    selectQuestion: function(selected) {
      console.log(selected)
      if (selected != SELECT_ALL) {
        sendClickEvent('select_question', selected)
        return
      } else {
        sendClickEvent('select_all', 'no_value')
        this.questions.forEach(this.addQuestion)
      }
    },

    showSpinner: function() {
      this.show_spinner = true
    },

    submit: function(event) {
      event.preventDefault()
      console.log('submitting form')
      this.showSpinner()
      sendClickEvent('submit_button', 'no_value')
      const questions = this.removeSelectAllFromQuestions(
        this.selected_questions
      )
      saveSubmission({
        email: this.form.email,
        link: this.form.disclosure_link,
        questions: questions,
      }).then(
        this.handleSuccess
      ).catch(
        this.handleError
      ).finally(
        this.hideSpinner
      )
    },

    removeSelectAllFromQuestions: function() {
      return this.selected_questions.filter(
        question => question != SELECT_ALL
      )
    },
  }
}
</script>


<style scoped>
  .btn-toolbar > .btn-group {
    flex-grow: 1;
  }

  .btn-toolbar > .input-group {
    flex-grow: 3;
  }
</style>
