<template>
  <div id="app">

    <Alerts />

    <img alt="Vue logo" src="./assets/logo.png">
    <p>Disclose reads through your real estate disclosure and answers your questions. No size limits. Turn around is 30 - 60 minutes. We send you an email once we have all the answers. <br> Never read another disclosure again!</p>

    <EmailForm />
  </div>
</template>



<script>
import Alerts from './components/Alerts'
import EmailForm from './components/EmailForm.vue'


export default {
  name: 'App',
  components: {
    Alerts,
    EmailForm,
  }
}
</script>



<style>
  html, body, #app {
    background-color: #b1d7ff;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
    margin-bottom: 60px;
    font-size: 20px;
  }

  #app p {
    font-size: 28px;
    font-weight: 500;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px;
  }
</style>
